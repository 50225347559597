import React, { useEffect } from "react";
import Footer from "../../CommonComponent/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllBlogsAction, getSingleResource, } from "../../../redux/actions/blogsList";
import { scroll } from "../../../utils/scrollTop";
import { resourceMetaData } from "../../../utils/metaData";
import Hero from "./Hero";
import DriveParticipation from "../../CommonComponent/DriveParticipation";
import TestimonialV2 from "../../CommonComponent/TestimonialV2";
import { videolandingPageAction } from "../../../redux/actions/videolandingPageAction.js";
import WeKnowManufacturing from "../../JobCoverMatrix/WeKnowManufacturing.js";
import OurSolution from "../../CommonComponent/OurSolution.js";
import RecentBlog from "../../BlogPage/SingleBlog/RecentBlog.js";
import Meet from "../../AboutPage/Meet.js";
import Info from "../../CommonComponent/Info.js";
// import ResourceContent from "./ResourceContent";
// import Features from "./Features";
// import Info from "./Info";

const VideoLanding = () => {
    const param = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        resourceMetaData(param?.id);
        scroll();
        dispatch(videolandingPageAction());
        dispatch(getAllBlogsAction());
        dispatch(getSingleResource(param?.id));
    }, [dispatch, param?.id]);
    const blog = useSelector((state) => state?.allblogs?.blogsList);
    const videolandingData = useSelector(
        (state) => state?.videolanding?.videolanding?.acf
    );
    const {
        setup_and_result: { setup_and_result } = {},
        we_know_manufacturing,
        our_solutions: oursolutions,
        our_leadership,
    } = videolandingData || {};
    const text_before_title = videolandingData?.setup_and_result?.text_before_title;
    const title = videolandingData?.setup_and_result?.title;
    const description = videolandingData?.setup_and_result?.description;
    const drive1 = setup_and_result?.right_image_align_section;
    const drive2 = setup_and_result?.left_image_align_section;
    const drive3 = setup_and_result?.right_image_align_section_next;
    const drive4 = setup_and_result?.left_image_align_section_next;
    return (
        <>
            {videolandingData ? (
                <>
                    <Hero />
                    <DriveParticipation
                        text_before_title={text_before_title}
                        title={title}
                        description={description}
                        card1={drive1}
                        card2={drive2}
                        card3={drive3}
                        card4={drive4}
                    />
                    <OurSolution data={oursolutions} />
                    <Meet data={our_leadership}/>
                    <WeKnowManufacturing data={we_know_manufacturing} />
                    <TestimonialV2 data={videolandingData?.testimonials_version_3} />
                    <RecentBlog blog={blog} />
                    <Info />
                    <Footer />
                </>
            ) : (
                []
            )}
        </>
    );
};

export default VideoLanding;

import React from "react";
import { styled } from "styled-components";
import { Color } from "../../../theme/Color";
import { Font } from "../../../theme/Font";
import {
  BannerButton,
  Section80,
  SectionTitle,
  SubTitle,
} from "../../DesignComponent/DesignComponents";
import Dummy from "../../../assets/SuggestionOperations/dummy.webp";
import VideoPoster from "../../../assets/NewSite/video-poster-img_11zon_11zon_11zon.webp";

const Hero = () => {
  const Banner = styled.section`
    background: #1c1c4b;
    // min-height:100vh;
    padding-top: 40px;
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  `;
  const VideoWrapper = styled.div`
    margin-top: -200px;
    // height:500px;
    @media (max-width: 767px) {
       margin-top: 0px;
      //  height:auto;
    }
  `;
  const SmallText = styled.p`
    font-size: 13px;
    font-family: ${Font.basel};
    letter-spacing: 1.04px;
  `;
  const styles = {
    decorator: {
      height: "5px",
      width: "20px",
      background: "rgb(141, 142, 165)",
      borderRadius: "10px",
      position: "absolute",
    },
    decorator2: {
      height: "20px",
      width: "5px",
      background: "rgb(141, 142, 165)",
      borderRadius: "10px",
      position: "absolute",
      top: "1.5rem",
      left: "-2px",
    },
  };
  return (
    <>
      <Banner className="hero-banner text-white text-center d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <SubTitle className="sub-title text-uppercase mb-2">
                Video
              </SubTitle>
              <SectionTitle className="banner-title mb-3">
                Lorem ipsum dolor sit amet consectetur. Dis vel vel odio feugiat
                mattis metus porta odio.
              </SectionTitle>
              {/* <Description
                                className="description mb-4 w-75 mx-auto"
                                style={{ color: Color.lightwhite }}>
                                {data?.description} */}
              {/* Build templates, collect data, and automate your processes with a user-friendly drag-and-drop interface. No coding required */}
              {/* </Description> */}
              <BannerButton to={"/webinar-form"} className="px-5 mb-4">
                CTA
              </BannerButton>
              {/* <img
                src={Dummy}
                alt={"Dummy"}
                className="w-100 d-lg-none d-block"
              /> */}
            </div>
          </div>
        </div>
      </Banner>
      <Section80 className="img-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <VideoWrapper className="position-relative">
                <div
                  style={{
                    ...styles.decorator,
                    top: "-2px",
                    left: "2rem",
                  }}
                ></div>
                <div
                  style={{
                    ...styles.decorator,
                    top: "-2px",
                    left: "3.6rem",
                  }}
                ></div>
                <div
                  style={{
                    ...styles.decorator2,
                  }}
                ></div>
                <div
                  className="video-wrapper h-100 shadow"
                  style={{
                    border: "6px solid #8D8EA5",
                    borderRadius: "21px",
                    background: "#8D8EA5",
                  }}
                >
                  <div
                    className="rounded-4 h-100"
                    style={{
                      border: "10px solid #8D8EA5",
                    }}
                  >
                    <video
                      className="w-100 h-100 rounded-4 "
                      controls
                      autoPlay
                      loop
                      muted
                      preload="metadata"
                      poster={VideoPoster}
                      style={{ objectFit: "cover" }}
                      loading="lazy"
                      fetchpriority="low"
                    >
                      <source
                        src={`https://staging.theleansuite.com/backend/wp-content/uploads/2024/06/home-page-video-continuous-improvement-process-0.9.webm`}
                        type="video/webm"
                      ></source>
                      <source
                        src={`https://staging.theleansuite.com/backend/wp-content/uploads/2024/06/home-page-video-continuous-improvement-process-0.9.mp4`}
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                </div>
              </VideoWrapper>
              {/* <Image src={Dummy} alt={"Dummy"} className="w-100" /> */}
            </div>
          </div>
        </div>
      </Section80>
    </>
  );
};

export default Hero;
